<template>
   <h2 class="subBanner pb0"><img src="@/assets/img/icon_gdia.svg" />{{$t('front.mypage.betList')}}</h2>
   <div class="w240 navSide">
     <ul class="boardnav">
        <template v-for="item in gameGroupCodes" v-bind:key="item.groupCode" >
          <li class="tab-link"
             :class="{'on': type == item.groupCode}"
             @click="type = item.groupCode"
          >
            <span>
               <em></em>{{$i18n.locale !== 'ko' ? item[`groupCodeName${$i18n.locale.toUpperCase()}`] : item.groupCodeName}}
            </span>
          </li>
        </template>
      <!--li>
        <span @click="goPageByName('qnaWrite')" class="csmenu112">
          <em></em>문의작성
        </span>
      </li-->
     </ul>
   </div>
  <div class="w1004">
    <div class="mybetwrap">
      <!--div class="boardName">
        <ul class="tabs">
          <li class="tab-link" @click="type = 'all'">{{$t('front.gameCategory.all')}}</li>
          <template v-for="item in gameGroupCodes" v-bind:key="item.groupCode" >
            <li class="tab-link"
                :class="{'current': type == item.groupCode}"
                @click="type = item.groupCode"
            >
              <span>{{$i18n.locale !== 'ko' ? item[`groupCodeName${$i18n.locale.toUpperCase()}`] : item.groupCodeName}}</span>
            </li>
          </template>
        </ul>
      </div-->

      <div class="tab-content">
        <div class="datesearch" v-if="commonCode" >
          <select v-model="vendorKey">
            <template v-for="item in commonCode[type]" v-bind:key="item.code">
              <option :value="item.code">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
              </option>
            </template>
          </select>
          <date-filter @search="loadList(1)"
                       @update="onChangeDate"
                       :startDate="searchDate.startDate"
                       :endDate="searchDate.endDate" :isOldYn="true"
                       @setOldYn="setOldYn" :oldYn="oldYn" />
        </div>

        <div class="board">
          <ul class="boardw first">
            <li class="">{{$t('front.board.dates')}}</li>
            <li class="">{{$t('front.board.company')}}</li>
            <li class="">{{$t('front.board.type')}}</li>
            <li class="">{{$t('front.board.gameType')}}</li>
            <!--li class="">{{$t('front.stributor.detailMore')}}</li-->
            <li class="">{{$t('front.bettingStatus.PASS')}}/{{$t('front.bettingStatus.FAIL')}}</li>
            <li class="">{{$t('front.board.betMoney')}}</li>
            <li class="">{{$t('front.board.revenue')}}</li>
            <li class="">{{$t('front.give.endMoney')}}</li>
          </ul>

          <template v-if="betList.length > 0">
            <template v-for="item in betList" v-bind:key="item.betIdx">
              <ul class="boardw">
                <li class="">{{dateFormatAll(item.createdAt)}}</li>
                <li class="">
                  {{$i18n.locale !== 'ko' ? item[`vendorName${$i18n.locale.toUpperCase()}`] : item.vendorName}}
                </li>
                <li class="">{{item.gameType}}</li>
                <li class="">{{item.gameName}}</li>
                <!--li class=""><a class="detailBtn" @click="detailP=!detailP">{{$t('front.stributor.detail')}}</a></li><-- {{item.gameIdx}} -->
                <li :class="{'rdc': item.betCalResults == 'PASS'}">
                  {{$t('front.bettingStatus.' + item.betCalResults)}}
                </li>
                <li class="" >{{thousand(item.cash)}}</li>
                <li class="">{{thousand(item.resultCash)}}</li>
                <li class="">{{thousand(item.afterCash)}}</li>
              </ul>
            </template>
          </template>
          <template v-else>
            <ul class="boardw">
              <li class="">{{$t('front.board.noBet')}}</li>
            </ul>
          </template>
          <div class="detailPop" v-if="detailP">
            <h4>{{$t('front.mypage.betListDetail')}}<a class="close" @click="detailP=!detailP"><img src="@/assets/img/bkclose.png" /></a></h4>
            <ul class="con">
              <li>
                <span>내역1</span>
                <span>10원</span>
              </li>
              <li>
                <span>내역2</span>
                <span>100원</span>
              </li>
              <li>
                <span>내역3</span>
                <span>1000원</span>
              </li>
              <li>
                <span>내역4</span>
                <span>10000원</span>
              </li>
              <li>
                <span>내역5</span>
                <span>100000원</span>
              </li>
            </ul>
          </div>
        </div>

        <pagination v-if="pageInfo"
                    :pageNum="pageInfo.page"
                    :pageSize="pageInfo.count_per_list"
                    :totalCount="pageInfo.tatal_list_count"
                    @goToPage="pageChange"
        />
      </div>
    </div>

  </div>
</template>

<script>

import Pagination from '@/components/ui/Pagination'
import DateFilter from '@/components/ui/DateFilter'
import { GAME_CATEGORY } from '@/libs/constants'
import { mapState } from 'vuex'

export default {
  name: 'bettingList',
  components: {
    Pagination,
    DateFilter
  },
  data () {
    return {
      type: null,
      groupCode: this.type,
      vendorKey: '',
      betList: [],
      gameList: GAME_CATEGORY,
      detailP: false,
      oldYn: 'N'
    }
  },
  computed: {
    ...mapState([
      'gameGroupCodes'
    ])
  },
  created () {
    if (this.gameGroupCodes && this.gameGroupCodes.length > 0) {
      this.type = this.gameGroupCodes[0].groupCode
      if (this.commonCode) {
        this.defaultValueSetting()
      }
    }
  },
  watch: {
    type (nVal, oVal) {
      // this.onBettingList(this.pageInfo.page, this.gameType)
      this.defaultValueSetting()
      this.loadList()
    },
    commonCode () {
      if (this.commonCode) {
        this.defaultValueSetting()
        this.loadList()
      }
    },
    vendorKey () {
      this.loadList()
    }
  },
  methods: {
    setOldYn (data) {
      this.oldYn = data
    },
    pageChange (page) {
      this.pageInfo.page = page
      this.loadList(page)
    },

    defaultValueSetting () {
      for (const item in this.commonCode[this.type]) {
        const code = this.commonCode[this.type][item].code
        if (code || code === 0) {
          this.vendorKey = code
          break
        }
      }
    },
    async loadList (page) {
      console.log('load------------')
      this.betList = await this.onBettingList(page, this.vendorKey, this.pageType, this.oldYn)
    }
  }
}
</script>
<style scoped>
.boardnav li:first-child em {background: url(~@/assets/img/smenu/icon_casino.svg) center no-repeat;}
.boardnav li + li em {background: url(~@/assets/img/smenu/icon_slot.svg) center no-repeat;}
.boardnav li:first-child:hover em, .boardnav li:first-child.on em {background: url(~@/assets/img/smenu/icon_casino_on.svg) center no-repeat;}
.boardnav li:hover em, .boardnav .on em {background: url(~@/assets/img/smenu/icon_slot_on.svg) center no-repeat;}

.boardName {margin-bottom: 20px;}
.gametab .liveicon1 {background: url("~@/assets/img/evolution_off.png") center no-repeat;}
.gametab .liveicon2 {background: url("~@/assets/img/asiagaming_off.png") center no-repeat;}
.gametab .liveicon3 {background: url("~@/assets/img/pragmaticplays_off.png") center no-repeat;}
.gametab .liveicon4 {background: url("~@/assets/img/dreamgaming_off.png") center no-repeat;}
.gametab .liveicon5 {background: url("~@/assets/img/sexyLogo_off.png") center no-repeat;}
.gametab .liveicon6 {background: url("~@/assets/img/biggaming_off.png") center no-repeat;}
.gametab .liveicon1:hover, .gametab  .on.liveicon1 {background: url("~@/assets/img/evolution_on.png") center no-repeat, linear-gradient(to bottom, #319e8a, #1c594e);}
.gametab .liveicon2:hover, .gametab .on.liveicon2 {background: url("~@/assets/img/asiagaming_on.png") center no-repeat, linear-gradient(to bottom, #319e8a, #1c594e);}
.gametab .liveicon3:hover, .gametab .on.liveicon3 {background: url("~@/assets/img/pragmaticplays_on.png") center no-repeat, linear-gradient(to bottom, #319e8a, #1c594e);}
.gametab .liveicon4:hover, .gametab .on.liveicon4 {background: url("~@/assets/img/dreamgaming_on.png") center no-repeat, linear-gradient(to bottom, #319e8a, #1c594e);}
.gametab .liveicon5:hover, .gametab .on.liveicon5 {background: url("~@/assets/img/sexyLogo_on.png") center no-repeat, linear-gradient(to bottom, #319e8a, #1c594e);}
.gametab .liveicon6:hover, .gametab .on.liveicon6 {background: url("~@/assets/img/biggaming_on.png") center no-repeat, linear-gradient(to bottom, #319e8a, #1c594e);}
.gametab a {height: 58px;padding: 0 !important;}
.gametab {gap: 8px !important;}
.boardpage {margin-top: 30px;}
.datesearch .datesearch {margin: 0;}
.boardw li {word-break: break-word}

.detailPop {min-width: 320px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, 0);background: #fff;border: 4px solid #1c594e;padding: 25px;border-radius: 10px;}
.detailPop h4 {display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #ccc;padding-bottom: 20px;margin-bottom: 20px;}
.detailPop a.close img {height: 18px;vertical-align: middle;}
.detailPop .con li {margin-top: 20px;display: flex;align-items: center;justify-content: space-between;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
